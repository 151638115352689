export const APP_BRANDING_NAME =
  "Foundational Literacy Instructional Tool for Educators";
export const APP_BRANDING_ACRONYM = "FLITE";

export const ORGANIZATION_NAME = "MGT";

export const ONLINE_LEARNING_CATALOG = "MGT Online Learning Library";

export const SUPPORT_EMAIL = "flitesupport@mgt.us";

export const PHONE_NUMBER = "610-398-1231";

export const COMPANY_ADDRESS = {
  NAME: ORGANIZATION_NAME,
  ADDRESS: "PO Box 230, Whitehall PA 18052-0230"
};

export const SOCIALS = {
  LINKEDIN: "https://www.linkedin.com/company/4974490",
  FACEBOOK: "https://www.facebook.com/stepbysteplearningLLC/",
  TWITTER: { URL: "https://x.com/SBSLinfo", HANDLE: "@SBSLinfo" }
};
